<template>
    <div class="countryCode">
        <div class="navBar">
            <div @click="backPage">
                <svg-icon class="navArrow" iconClass="navArrow" />
            </div>
            <div class="navTitle">选择国家或地区</div>
            <div class="rightBtn"></div>
        </div>
        <div class="main">
            <div class="countryItem" v-for="(item, index) in countryList" :key="index" @click="selectCity(item)">
                <div class="city">{{item.city}} {{item.code == areaCode ? '(当前选择)' : ''}}</div>
                <div class="cityCode">{{item.code}}</div>
            </div>
        </div>
    </div>
</template>

<script>
    import countryJSON from "@/assets/json/country_code.json";
    import { mapGetters } from "vuex";
    export default {
        data() {
            return {
                countryList: countryJSON.list
            }
        },
        computed: {
            ...mapGetters({
                areaCode: 'areaCode',
            }),
        },
        methods: {
            // 返回
            backPage() {
                this.$router.go(-1);
            },
            // 选择城市
            selectCity(item) {
                this.$store.commit('user/SET_AREACODE', item.code);
                this.$router.go(-1);
            }
        }
    }
</script>

<style lang="scss" scoped>
    .countryCode {
        height: 100%;
        background: #f5f5f5;
        .navBar {
            height: 44px;
            padding: 0 12px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: #000;
            background: #fff;
            // border-bottom: 1px solid #2b274a;

            .navArrow {
                width: 17px;
                height: 17px;
            }

            .navTitle {
                // flex: 2;
                // text-align: center;
                font-size: 18px;
            }

            .rightBtn {
                width: 17px;
                height: 17px;
            }
        }
        .main {
            height: calc(100% - 44px);
            overflow-y: auto;
            .countryItem {
                padding: 0 12px;
                height: 44px;
                font-size: 15px;
                border-bottom: 1px solid #e6e6e6;
                color: #000;
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
        }
    }
</style>